<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading" (click)="openConfirmDialog()"><svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </button>
        {{"general.Edit"| translate}} {{ 'companyAdminProfile.Commercial and tax registry data' | translate }}
    </div>
    <form [formGroup]="formGroup">
        <div class="dialog_content pb-0" mat-dialog-content>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"companies.Commercial registration No."|translate}}</mat-label>
                    <input matInput type="text" appOnlynumber maxlength="20" formControlName="license_number">
                    <mat-error *ngIf="hasError('license_number','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <app-file-manager [maxFiles]="1" [allowedExt]="['jpg', 'png','jpeg','pdf']" [maxFileSize]="2"
                    [files]="data?.license_file_object?[data?.license_file_object]:[]"
                    (selectedFiles)="OnSelectLicense_attachment($event)" managerIndex="license_attachment"
                    [label]="'companies.cerificate of Commercial registry document'|translate"></app-file-manager>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"companies.Tax Number"|translate}}</mat-label>
                    <input matInput type="text" appOnlynumber maxlength="20" formControlName="tax_number">
                    <mat-error *ngIf="hasError('tax_number','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <app-file-manager [maxFiles]="1" [allowedExt]="['jpg', 'png','jpeg','pdf']" [maxFileSize]="2"
                    [files]="data?.tax_number_object?[data?.tax_number_object]:[]"
                    (selectedFiles)="OnSelectTax_attachment($event)" managerIndex="tax_attachment"
                    [label]="'companies.Registrartion Document of added VAT System'|translate"></app-file-manager>
            </div>

        </div>



        <div class="dialog_actions" mat-dialog-actions>
            <button mat-button type="button" [disabled]="loading" class="btn-secondary"
                (click)="openConfirmDialog()">{{"general.Cancel"|translate}}</button>

            <button mat-button type="button" [disabled]="loading" [class.loading]="loading" (click)="OnSave()"
                class="btn-primary">
                {{"general.Edit"| translate}}
            </button>
        </div>
    </form>
</div>