import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { ProfileService } from './services/profile.service';
import { AuthenticationService } from '../../services/authentication.service';
import { UpdateProfileComponent } from './components/update-profile/update-profile.component';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styles: ``
})
export class MyProfileComponent implements OnInit {
  loading: boolean = false;
  userData: any = {};
  type!: any;
  constructor(private _dialog: MatDialog,
    private _profileService: ProfileService,
    private _AuthService: AuthenticationService) {

  }
  ngOnInit(): void {
    this.OnGetUserData();
    this.type = this._AuthService.currentUserValue?.type;
  }

  OnUpdatePassword() {
    const dialogRef = this._dialog.open(UpdatePasswordComponent, {
      width: '540px'
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._AuthService.logout()
      }
    })
  }

  OnUpdateProfile() {
    const dialogRef = this._dialog.open(UpdateProfileComponent, {
      width: '540px',
      data: this.userData
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.OnGetUserData();
      }
    })
  }

  OnGetUserData() {
    this.loading = true;
    let userId: string = this._AuthService.currentUserValue?.id || ""
    this._profileService.viewUser(userId).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.userData = res.data
      },
      error: (error: any) => {
        this.loading = false;

      }
    })
  }
}
