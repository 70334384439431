import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DirtyComponent } from '../../../../../../helpers/CanDeactivate/dirty-component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { VendorProfileService } from '../../../services/vendor-profile.service';
import Swal from 'sweetalert2';
import { Patterns } from '../../../../../../helpers/patterns';

export interface DialogData {
  details: any
}

@Component({
  selector: 'app-update-social-info',
  templateUrl: './update-social-info.component.html',
  styles: ``
})
export class UpdateSocialInfoComponent extends DirtyComponent {
  loading = false;
  dataLoading = false;
  override formGroup!: FormGroup;
  isSubmitted = false;
  details!: any;

  constructor(
    override dialogRef: MatDialogRef<UpdateSocialInfoComponent>,
    translate: TranslateService,
    private formBuilder: FormBuilder,
    private service: VendorProfileService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    super(translate);
  }

  ngOnInit(): void {
    this.details = this.data?.details;
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      vendor_phone_number: [
        this.details?.phone_number, [
          Validators.maxLength(10),
          Validators.minLength(8)
        ]
      ],
      vendor_email: [
        this.details?.email, [
          Validators.maxLength(100),
          Validators.pattern(Patterns.email)
        ]
      ],
      website: [this.details?.website, [Validators.pattern(Patterns.website), Validators.maxLength(100)]],
      facebook_link: [
        this.details?.social_media_links.facebook_link, [
          Validators.pattern(Patterns.website),
          Validators.maxLength(100)
        ]
      ],
      instagram_link: [
        this.details?.social_media_links.instagram_link, [
          Validators.pattern(Patterns.website),
          Validators.maxLength(100)
        ]
      ],
      x_link: [
        this.details?.social_media_links.x_link, [
          Validators.pattern(Patterns.website),
          Validators.maxLength(100)
        ]
      ],
      whatsapp: [this.details?.social_media_links?.whatsapp, [Validators.maxLength(10), Validators.minLength(8)]],
      snapchat_link: [this.details ? this.details?.social_media_links?.snapchat : null, [Validators.pattern(Patterns.website), Validators.maxLength(100)]],
      tiktok_link: [this.details ? this.details?.social_media_links?.tiktok : null, [Validators.pattern(Patterns.website), Validators.maxLength(100)]],
    })
  }
  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;
      let model = { ...this.formGroup.value }
      // if (model.vendor_phone_number) {
      //   model.vendor_phone_number = '05' + model.vendor_phone_number;
      // }
      this.service.updateVendorContactInfo(this.details.id, model).subscribe((data) => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.updatedsuccessfully'),
          text: '',
          imageUrl: './assets/images/icons/success.svg',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
      });
    }
  }
}
