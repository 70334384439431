import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vendor-registery-data',
  templateUrl: './vendor-registery-data.component.html',
  styles: ``
})
export class VendorRegisteryDataComponent {

  @Input() vendorDetails!: any;
}
