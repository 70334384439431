<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading"  (click)="openConfirmDialog()" ><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="ic_close"
                d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                transform="translate(-204.746 755.5)" fill="#999" />
        </svg>
    </button>
        {{"users.Edit Info" | translate}}
    </div>

    <form [formGroup]="formGroup" ngNativeValidate id="UpdateProfileForm" (ngSubmit)="saveRecord()">
        <div class="dialog_content" mat-dialog-content>
            <ng-container *ngIf='!dataLoading'>
                <!-- <app-profile-image-manager [label]="'users.profile picture' | translate" [maxFileSize]="2"
                    [managerIndex]="'profile_image'" [defualtImage]="details?.image"
                    (onImageChanged)="OnSelectProfileImage($event)"></app-profile-image-manager> -->

                <app-image-control
                  [label]="'users.profile picture' | translate"
                  [managerIndex]="'profile_image'"
                  [ratio]="1 / 1"
                  [src]="details?.image"
                  (imageReady)="OnSelectProfileImage($event)">
                </app-image-control>

                <div class="mt-5">
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{ "general.Full Name" | translate }}</mat-label>
                            <input matInput formControlName="full_name" maxlength="100">
                            <mat-error *ngIf="hasError('full_name', 'required')">
                                {{ 'forms.Required' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="phoneNumberleft">
                            <mat-label>{{ "general.Mobile Number" | translate }}</mat-label>
                            <input matInput type="tel" formControlName="phone_number" appOnlynumber maxlength="8">
                            <span matSuffix>+966 <b>5</b></span>
                            <mat-error *ngIf="hasError('phone_number', 'required')">
                                {{ 'forms.Required' | translate}}</mat-error>
                            <mat-error *ngIf="hasError('phone_number', 'max')">{{ 'forms.Phone Number Length should be' |
                                translate : {num:10} }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{ "login.Email" | translate }}</mat-label>
                            <input matInput formControlName="email" maxlength="100" type="email">
                            <mat-error *ngIf="hasError('email', 'required')">{{ 'forms.Required' | translate
                                }}</mat-error><mat-error *ngIf="hasError('email', 'invalid')">{{ 'forms.Invalid-Email' |
                                translate }}</mat-error>
                            <mat-error *ngIf="hasError('email', 'pattern')">{{ 'forms.Invalid-Email' | translate
                                }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3" *ngIf="currentUserType == 'admin' || currentUserType == 'company' || currentUserType == 'vendor'">
                        <mat-form-field>
                            <mat-label>{{ "userProfile.Job title" | translate }}</mat-label>
                            <input matInput formControlName="job_title" maxlength="100">
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            <div *ngIf='dataLoading' class="dataLoading">
                <mat-spinner class="m-auto" diameter="50"></mat-spinner>
            </div>
        </div>
        <div class="dialog_actions" mat-dialog-actions>
            <button mat-button class="btn-secondary" type="button" [disabled]="loading"
                (click)="openConfirmDialog()">{{"general.Cancel"|translate}}</button>
            <button mat-button class="btn-primary" type="submit" form="UpdateProfileForm" [disabled]="loading"
                [class.loading]="loading">{{"general.Edit" | translate}}</button>
        </div>
    </form>
</div>
