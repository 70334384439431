import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DirtyComponent } from '../../../../helpers/CanDeactivate/dirty-component';
import { ConfigService } from '../../../../helpers/config.service';
import { Patterns } from '../../../../helpers/patterns';
import { CompanyProfileService } from '../services/company-profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-update-company-info',
  templateUrl: './update-company-info.component.html',
  styles: ``
})
export class UpdateCompanyInfoComponent extends DirtyComponent implements OnInit {

  override formGroup!: FormGroup<any>;
  loading: boolean = false;

  loadingCities: boolean = false;
  CitesList: any[] = [];
  citesModal = { perPage: 200, page: 1, search: '' }
  citiesCount: number = 0;
  subscribtion: Subscription[] = [];
  isUpdate = true;
  constructor(override dialogRef: MatDialogRef<UpdateCompanyInfoComponent>,
    translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _companyProfileService: CompanyProfileService,
    private _config: ConfigService) {
    super(translate)

  }

  ngOnInit(): void {
    this.GetCities();
    this.formGroup = this.formBuilder.group({
      city_id: [this.data.city_id ? this.data.city_id : null, [Validators.required]],
      address_ar: [this.data.address_ar ? this.data.address_ar : null, [Validators.pattern(Patterns.arTxtWithSpecial), Validators.maxLength(100)]],
      address_en: [this.data.address_en ? this.data.address_en : null, [Validators.pattern(Patterns.enTxtWithSpecial), Validators.maxLength(100)]],
      company_phone_number: [this.data.phone_number ? this.data.phone_number : null, [Validators.maxLength(10), Validators.minLength(8)]],
      company_email: [this.data.email ? this.data.email : null, [Validators.maxLength(100), Validators.pattern(Patterns.email)]],
    });
    if (this.data?.city_id) {
      this.isUpdate = true;
      this.formGroup.controls['city_id'].disable();
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }


  OnSave() {
    if (this.formGroup.valid) {
      this.loading = true;
      let model = { ...this.formGroup.value };
      // if (model.company_phone_number) {
      //   model.company_phone_number = "05" + model.company_phone_number
      // }
      model.city_id = this.data.city_id ? this.data.city_id : model.city_id
      this._companyProfileService.UpdateCompanyInfo(this.data.id, model).subscribe({
        next: (res: any) => {
          this.loading = false;
          this._config.SusseccMessage(this._config._translate.instant("swalMsg.updatedsuccessfully"));
          this.dialogRef.close(true);
        },
        error: (error: any) => {
          this.loading = false;
        },
      })
    }
  }


  GetCities() {
    this.subscribtion.forEach(element => {
      element.unsubscribe();
    });
    this.loadingCities = true;
    // this.citesModal.page = this.city_pageIndex;
    // this.citesModal.perPage = this.city_pageSize;
    this.subscribtion.push(this._companyProfileService.GetCities(this.citesModal).subscribe({
      next: (res: any) => {
        this.loadingCities = false;
        this.CitesList = this.CitesList.concat(res.data.list);
        this.citiesCount = res.data.total;
      },
      error: (error: any) => {
        this.loadingCities = false;
      }
    }))
  }


  // City with Scroll

  dropSearch(data: any) {
    this.CitesList = [];
    this.citesModal.search = data?.term || '';
    this.GetCities();
  }

  OnClear() {
    this.formGroup.patchValue({ city_id: null })
    this.citesModal.search = ''
    this.CitesList = [];
    this.GetCities();
  }



}
