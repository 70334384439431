<div class="container-fluid p-0">
    <mat-dialog-content class="py-0 pl-2 pr-0 large-content">
        <div class="row">
            <div class="col-12 col-lg-8 chooseColor">
                <div class="header">
                    <div class="text">
                        <div class="title"> {{"changeColor.Mobile app and website colors"|translate}}</div>
                        <div class="hint">
                           {{"changeColor.From here, you will be able to easily determine the colors that the mobile application or website of your employees will be."|translate}}
                        </div>
                    </div>
                    <div class="logo">
                        <img [src]="data.logo" *ngIf="data.logo" (error)="data.logo=''" alt="company Logo">
                        <span *ngIf="!data.logo">{{data?.name|shortName}}</span>
                    </div>
                </div>

                <div class="select_color">
                    <div class="switch_color">
                        <div class="color_card">
                            <p> {{"changeColor.Base color"|translate}}</p>
                            <div class="preview_color_box linkable" [ngClass]="{'active': selectedType=='main'}"
                                (click)="selectedType = 'main'">
                                <span [ngStyle]="{'background-color': formGroup.value.color}"></span>
                            </div>
                        </div>
                        <div class="color_card">
                            <p> {{"changeColor.Text color"|translate}}</p>
                            <div class="preview_color_box linkable" [ngClass]="{'active': selectedType=='text'}"
                                (click)="selectedType = 'text'">
                                <span [ngStyle]="{'background-color': formGroup.value.font_color}"></span>
                            </div>
                        </div>
                    </div>
                    <app-color-picker *ngIf="selectedType=='main'" (selectedColor)="onSetColorValue('color',$event)"
                        [defualtColor]="formGroup.value.color"></app-color-picker>

                    <app-color-picker *ngIf="selectedType=='text'"
                        (selectedColor)="onSetColorValue('font_color',$event)"
                        [defualtColor]="formGroup.value.font_color"></app-color-picker>


                </div>
                <div class="actions">

                    <button mat-button type="button" class="btn-secondary"
                        (click)="openConfirmDialog()">{{"general.Cancel"|translate}}</button>
                    <div>

                        <a class="btn_link" (click)="onResetColors()">
                            {{"changeColor.Reset (default colors)"|translate}}</a>
                        <button mat-button type="button" class="btn-primary" (click)="onSubmitColors()">
                            {{"changeColor.Update colors"|translate}}
                        </button>
                    </div>

                </div>
            </div>
            <div class="col-12 col-lg-4 preview_theme">
                <div class="title text-center"> {{"changeColor.Color preview"|translate}}</div>
                <app-svg-custom-color [svgType]="'Large'" [svgMainColor]="formGroup.value.color"
                    [svgTextColor]="formGroup.value.font_color"></app-svg-custom-color>
            </div>
        </div>
    </mat-dialog-content>
</div>