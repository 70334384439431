<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading" (click)="openConfirmDialog()"><svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </button>
        {{ 'vendorAdminProfile.Update vendor category' | translate }}
    </div>
    <div class="dialog_content p-0" mat-dialog-content>
        <!-- <div class="search">
            <div class="search_input font-14">
                <a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.997" viewBox="0 0 16 15.997">
                        <path id="search"
                            d="M17.754,16.627l-2.966-2.944A7.194,7.194,0,1,0,13.677,14.8l2.942,2.944a.795.795,0,0,0,1.135-1.112ZM9.192,14.8a5.6,5.6,0,1,1,5.6-5.6,5.6,5.6,0,0,1-5.6,5.6Z"
                            transform="translate(-1.979 -1.979)" fill="#666" />
                    </svg>
                </a>
                <input type="text" [placeholder]="'vendorAdminProfile.Search for a vendor category'|translate">
            </div>
        </div> -->
        <div *ngIf='categoriesLoading' class="dataLoading py-5 d-block m-auto">
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>
        <div class="position-relative" *ngIf="!categoriesLoading">
            <div class="dialog-padding">
                <div class="font-22 fontMedium m-3">
                    {{"vendors.Vendor Category (Service Type)"|translate}}
                </div>
            </div>
            <app-tree-selection-with-img [TREE_DATA]="categoriesList" (selectedItems)="selectedCategories = $event; changeSelected($event)"
                [selected]="selectedCategories"></app-tree-selection-with-img>
        </div>
    </div>
    <div class="dialog_actions" mat-dialog-actions>
        <button mat-button (click)="openConfirmDialog()" [disabled]="loading"
            class="btn-secondary">{{"general.Cancel"|translate}}</button>
        <button mat-button class="btn-primary" [disabled]="loading" [class.loading]="loading" (click)="saveRecord()">
            {{ 'vendorAdminProfile.Update' | translate }}</button>
    </div>
</div>