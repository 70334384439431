import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  lang = localStorage.getItem('language') || 'ar';
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public location: Location,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 429) { // Too many requests
          // debugger
          this.snackBar.open(
            this.lang == 'en' ? 'Server Issue !' : 'مشكلة بالخادم !',
            this.lang == 'en' ? 'Ok' : 'حسنا',
            {
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
        }
         if (err.status === 403) {
          // this.router.navigate(['/admin/notAuthorized'])
          
          this.router.navigate(['/admin/profile']);
          // this.authenticationService.logout();
        }
        if (err.status === 401) {
          // this.authenticationService.logout();
          // this.router.navigate(['/admin/notAuthorized']);
          localStorage.removeItem('authUser');
          localStorage.removeItem('token');
          let lang = localStorage.getItem("language")
          localStorage.clear();
          localStorage.setItem('language', lang || '');
          // this.router.navigate(['/']);
          window.location.reload();
        }

        if (err.status === 0) { // Offline
          this.snackBar.open(
            this.lang == 'en' ? 'You are offline!' : 'أنت غير متصل!',
            this.lang == 'en' ? 'Ok' : 'حسنا',
            {
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          // this.snackBar.open(this.translate.instant('general.You are offline!'), this.translate.instant('swalMsg.Ok'), {
          //   horizontalPosition: 'center',
          //   verticalPosition: 'top'
          // });
        }

        // const error = err.error.message || err.statusText;
        return throwError(err.error);
      })
    );
  }
  showError(msg: string) { }
}
