<div class="small-empty">
    <div class="working-hours">
        <div class="hint-opening-time">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path id="ic_opening_hours"
                    d="M0,10A10,10,0,1,1,10,20,10,10,0,0,1,0,10Zm1.818,0A8.182,8.182,0,1,0,10,1.818,8.182,8.182,0,0,0,1.818,10Zm10.267,3.37L9.357,10.643A.911.911,0,0,1,9.091,10V4.546a.909.909,0,1,1,1.818,0V9.624l2.461,2.461a.909.909,0,1,1-1.285,1.285Z"
                    fill="#a19980" />
            </svg>
            <span class="danger">{{ 'workingHours.The store is now closed' | translate }}</span>
            <span>{{ 'workingHours.Opens' | translate }} السبت 8:00 ص</span>
        </div>
        <div class="opening-times">
            <div class="table-responsive table-container mt-3">
                <table class="table table-primary">
                    <tbody>
                        <!-- <tr *ngFor="let day of recordsData?.data">
                            <td class="font-18 fontSemiBold text-color" style="width: 10%;">{{day.name}}</td>
                            <td class="font-18 grey-color">
                                <ng-container *ngIf="day.start_at && day.closed_at; else closed">
                                    {{day.start_at}} - {{day.closed_at}}
                                </ng-container>
                                <ng-template #closed>
                                    {{'workingHours.closed' | translate}}
                                </ng-template>
                            </td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>