<nav class="head-nav d-flex">
    <h2>{{ "companyAdminProfile.Company profile" | translate }}</h2>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="/admin/profile">{{ "general.Home" | translate }}</a>
        </li>
    </ol>
</nav>

<div *ngIf='dataLoading' class="dataLoading py-5">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-container *ngIf="!dataLoading">
    <div class="company_Offer_Profile company-admin">
        <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div class="top-profile-card">
                    <div class="top-profile-card-content">
                        <div class="logo-card">
                            <img *ngIf="CompanyDetails?.logo" [src]="CompanyDetails?.logo"
                                (error)="CompanyDetails.logo=''" alt="">
                            <span *ngIf="!CompanyDetails?.logo">{{ CompanyDetails.name | shortName }}</span>
                        </div>
                        <div class="top-info">
                            <div class="top-profile-card-header">
                                <div class="badges d-flex">

                                    <ng-container *ngIf="CompanyDetails?.status == 'active'">
                                        <div class="status-badge green">
                                            {{'users.Active Account' | translate}}
                                            <img src="./assets/images/icons/Verified_active_account_ic.svg" alt="">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="CompanyDetails?.status == 'suspend'">
                                        <div class="status-badge red">
                                            {{'users.Suspended Account' | translate}}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.998" height="16.999"
                                                viewBox="0 0 19.998 16.999">
                                                <path id="suspend_ic"
                                                    d="M20071,10694a5,5,0,1,1,5,5A5,5,0,0,1,20071,10694Zm1,0a3.993,3.993,0,0,0,6.451,3.155l-5.6-5.6A3.918,3.918,0,0,0,20072,10694Zm1.553-3.154,5.6,5.6a3.934,3.934,0,0,0,.846-2.449,4,4,0,0,0-4-4A3.938,3.938,0,0,0,20073.551,10690.844ZM20061,10698v-2.656a2.918,2.918,0,0,1,1.387-2.524A12.875,12.875,0,0,1,20069,10691c.227,0,.449.01.67.021a6.972,6.972,0,0,0,.59,6.979Zm4-12a4,4,0,1,1,4,4A4.005,4.005,0,0,1,20065,10686Z"
                                                    transform="translate(-20061.002 -10681.999)" fill="#999" />
                                            </svg>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="CompanyDetails?.status == 'not_verified'">
                                        <div class="status-badge yellow">
                                            {{'companyProfile.Not verified Account' | translate}}
                                            <img src="./assets/images/icons/not_verified_ic.svg" alt="">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="CompanyDetails?.status == 'expired'">
                                        <div class="status-badge red">
                                            {{'users.Expired Account' | translate}}
                                        </div>
                                    </ng-container>
                                    <div class="status-badge">
                                        {{'companyProfile.Subscription Package' | translate}}
                                        <b>{{CompanyDetails?.package?.name_en}} -
                                            {{CompanyDetails?.package?.name_ar}}</b>
                                    </div>
                                </div>
                            </div>

                            <div class="top-profile-card-details">
                                <h3>{{CompanyDetails?.name_ar}}</h3>
                                <!-- <h3>تكافل الراجحي</h3> -->
                                <h4>{{CompanyDetails?.name_en}}</h4>
                                <!-- <h4>Al Rajhi Takaful</h4> -->

                                <div class="d-flex mt-3 align-items-baseline">
                                    <p>
                                        <span>{{ 'companyAdminProfile.Joining Date' | translate }}:</span>
                                        <b>{{CompanyDetails?.created_at| date :'d MMMM y - h:mm a'}}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div class="points-card">
                    <div class="img-card pb-3">
                        <!-- <img src="/assets/images/coins.svg" alt="coins image" class="mb-3 coins_img"> -->
                        <div class="points-info">
                            <h6>{{ 'employeeProfile.Current Points' | translate }}</h6>
                            <h2>{{CompanyDetails?.balance?.points | number}} <span>{{ 'companies.point' | translate }}</span>
                            </h2>
                            <button mat-button class="btn-primary mb-3" *ngIf="permissions?.can_recharge_wallet"
                                (click)="OnChargeWallet()">
                                {{"companyAdminProfile.Wallet charging" | translate}}
                            </button>
                            <p *ngIf="CompanyDetails?.balance?.Last_transaction_date">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                                    <path id="event_FILL1_wght400_GRAD0_opsz24"
                                        d="M128.944-867.2a1.851,1.851,0,0,1-1.381-.58A1.96,1.96,0,0,1,127-869.2a1.959,1.959,0,0,1,.564-1.42,1.851,1.851,0,0,1,1.381-.58,1.851,1.851,0,0,1,1.381.58,1.959,1.959,0,0,1,.564,1.42,1.96,1.96,0,0,1-.564,1.42A1.851,1.851,0,0,1,128.944-867.2Zm-7.389,3.2a1.477,1.477,0,0,1-1.1-.47A1.563,1.563,0,0,1,120-865.6v-11.2a1.563,1.563,0,0,1,.457-1.13,1.478,1.478,0,0,1,1.1-.47h.778v-.8a.785.785,0,0,1,.224-.57.741.741,0,0,1,.554-.23.741.741,0,0,1,.554.23.786.786,0,0,1,.224.57v.8h6.222v-.8a.785.785,0,0,1,.224-.57.741.741,0,0,1,.554-.23.741.741,0,0,1,.554.23.785.785,0,0,1,.224.57v.8h.778a1.478,1.478,0,0,1,1.1.47A1.563,1.563,0,0,1,134-876.8v11.2a1.563,1.563,0,0,1-.457,1.13,1.477,1.477,0,0,1-1.1.47Zm0-1.6h10.889v-8H121.556Z"
                                        transform="translate(-120 880)" fill="#b0b0be" />
                                </svg>

                                <span>{{ 'companyProfile.Date of last transaction' | translate }}</span>
                                <b>{{CompanyDetails?.balance?.Last_transaction_date | date :'d MMMM y - h:mm a' }}</b>
                            </p>

                            <div class="d-flex justify-content-between">
                                <button mat-button *ngIf="permissions?.can_distribut" class="btn-light light-color m-1"
                                (click)="OnSendPoints()">
                                    {{"companyAdminProfile.Distribution of points" | translate}}
                                </button>
                                <button mat-button *ngIf="permissions?.can_withdrawal_request"
                                    class="btn-light light-color m-1" (click)="requestWithdrawalRequest()">
                                    {{"companyAdminProfile.Submit a withdrawal request" | translate}}
                                </button>
                            </div>

                            <a (click)="selectedCompanyId = CompanyDetails.id" *ngIf="permissions?.can_show_transactions"
                                class="mb-5 font-14 fontRegular white-text">
                                {{ 'companyProfile.Transaction history on the wallet' | translate }}
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                    <path id="ic_arrow_link"
                                        d="M213.88-756.648l9.444,9.432a.633.633,0,0,0,.474.216.671.671,0,0,0,.486-.216.671.671,0,0,0,.216-.486.672.672,0,0,0-.216-.486l-9.432-9.432h8.424a.661.661,0,0,0,.489-.2.669.669,0,0,0,.195-.492.676.676,0,0,0-.195-.494.658.658,0,0,0-.489-.2H213.2a.673.673,0,0,0-.495.2.673.673,0,0,0-.2.495v10.08a.651.651,0,0,0,.2.489.69.69,0,0,0,.5.195.656.656,0,0,0,.488-.195.669.669,0,0,0,.192-.489Z"
                                        transform="translate(-212.5 759)" fill="#fff" />
                                </svg>
                            </a>
                        </div>

                        <!-- <a class="list">
                            {{ 'companyProfile.Transaction history on the wallet' | translate }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                <path id="ic_arrow_link"
                                    d="M213.88-756.648l9.444,9.432a.633.633,0,0,0,.474.216.671.671,0,0,0,.486-.216.671.671,0,0,0,.216-.486.672.672,0,0,0-.216-.486l-9.432-9.432h8.424a.661.661,0,0,0,.489-.2.669.669,0,0,0,.195-.492.676.676,0,0,0-.195-.494.658.658,0,0,0-.489-.2H213.2a.673.673,0,0,0-.495.2.673.673,0,0,0-.2.495v10.08a.651.651,0,0,0,.2.489.69.69,0,0,0,.5.195.656.656,0,0,0,.488-.195.669.669,0,0,0,.192-.489Z"
                                    transform="translate(-212.5 759)" fill="#49d6d6" />
                            </svg>
                        </a> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="vertical-tabs mb-5">
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                                    <path id="ic_contacts"
                                        d="M50.909-788.194a10.03,10.03,0,0,1,2.011-.713,9.07,9.07,0,0,1,2.08-.237,9.374,9.374,0,0,1,1.6.136,10.348,10.348,0,0,1,1.58.407v-8.956a7.9,7.9,0,0,0-1.557-.475A8.416,8.416,0,0,0,55-798.191a8.377,8.377,0,0,0-2.114.271,7.8,7.8,0,0,0-1.977.814ZM50-786a2.233,2.233,0,0,1-.6-.079,2.549,2.549,0,0,1-.534-.215,7.457,7.457,0,0,0-1.864-.78,7.743,7.743,0,0,0-2-.26,7.153,7.153,0,0,0-1.875.249,8.248,8.248,0,0,0-1.761.7.879.879,0,0,1-.92-.023A.873.873,0,0,1,40-787.2v-10.9a.968.968,0,0,1,.125-.475.783.783,0,0,1,.375-.339,11.275,11.275,0,0,1,2.193-.8A9.428,9.428,0,0,1,45-800a9.89,9.89,0,0,1,2.58.339A9.825,9.825,0,0,1,50-798.643a9.825,9.825,0,0,1,2.42-1.018A9.89,9.89,0,0,1,55-800a9.428,9.428,0,0,1,2.307.283,11.275,11.275,0,0,1,2.193.8.783.783,0,0,1,.375.339A.968.968,0,0,1,60-798.1v10.9a.873.873,0,0,1-.443.792.879.879,0,0,1-.92.023,8.248,8.248,0,0,0-1.761-.7A7.153,7.153,0,0,0,55-787.334a7.743,7.743,0,0,0-2,.26,7.457,7.457,0,0,0-1.864.78,2.549,2.549,0,0,1-.534.215A2.233,2.233,0,0,1,50-786Z"
                                        transform="translate(-40 800)" fill="#8e8fa2" />
                                </svg>
                                {{ 'companyAdminProfile.Address and contact information' | translate }}
                            </ng-template>
                            <div class="card">
                                <div class="card-head">
                                    <div class="card-title">
                                        <h4>{{ 'companyAdminProfile.Address and contact information' | translate }}</h4>
                                    </div>
                                    <div class="card-actions">
                                        <button mat-icon-button (click)="OnUpdateCompanyInfo()" type="button" *ngIf="permissions?.can_update"
                                            [matTooltip]="'general.Edit' | translate">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <div class="info-data" *ngIf="CompanyDetails?.city_name">
                                        <div class="icon-card">
                                            <img src="./assets/images/icons/map-marker-icon.svg" alt="">
                                        </div>
                                        <div>
                                            <label>{{'companies.Company Address' | translate}}</label>
                                            <div> <span *ngIf="CompanyDetails?.address">{{CompanyDetails?.address}}, </span>{{CompanyDetails?.city_name}}</div>
                                        </div>
                                    </div>
                                    <div class="info-data" *ngIf="CompanyDetails?.phone_number">
                                        <div class="icon-card">
                                            <img src="./assets/images/icons/ic_phone.svg" alt="">
                                        </div>
                                        <div>
                                            <label>{{'general.Mobile Number' | translate}}</label>
                                            <div class="always-ltr">+(966) {{CompanyDetails?.phone_number}}</div>
                                        </div>
                                    </div>
                                    <div class="info-data" *ngIf="CompanyDetails?.email">
                                        <div class="icon-card">
                                            <img src="./assets/images/icons/ic_email.svg" alt="">
                                        </div>
                                        <div>
                                            <label>{{'login.Email' | translate}}</label>
                                            <div>
                                                <a class="email"
                                                    href="mailto:{{CompanyDetails?.email}}">{{CompanyDetails?.email}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <path id="plans_ic"
                                        d="M92.265-860.576a1.938,1.938,0,0,1-1.427.576,1.937,1.937,0,0,1-1.427-.576L80.6-869.387a2.079,2.079,0,0,1-.438-.651,1.993,1.993,0,0,1-.163-.8V-878a1.928,1.928,0,0,1,.588-1.414A1.928,1.928,0,0,1,82-880h7.159a1.992,1.992,0,0,1,.8.163,2.078,2.078,0,0,1,.651.438l8.811,8.836a1.923,1.923,0,0,1,.576,1.414,1.923,1.923,0,0,1-.576,1.414Zm-7.76-13.417a1.448,1.448,0,0,0,1.064-.438,1.448,1.448,0,0,0,.438-1.064,1.448,1.448,0,0,0-.438-1.064A1.449,1.449,0,0,0,84.506-877a1.449,1.449,0,0,0-1.064.438A1.448,1.448,0,0,0,83-875.494a1.448,1.448,0,0,0,.438,1.064A1.448,1.448,0,0,0,84.506-873.992Z"
                                        transform="translate(-80 880)" fill="#8e8fa2" />
                                </svg>
                                {{ 'companyAdminProfile.Subscriptions details' | translate }}
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="card">
                                    <div class="card-head">
                                        <div class="card-title">
                                            <h4>{{ 'companyAdminProfile.Subscriptions details' | translate }}</h4>
                                        </div>
                                    </div>
                                    <app-company-subscriptions-details [companyId]="CompanyDetails.id" [isActive]="1"
                                        [index]="1"></app-company-subscriptions-details>
                                </div>
                            </ng-template>

                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <path id="ic_tax_and_license_naumber"
                                        d="M134.275-824.875l-.425-.425a.734.734,0,0,0-.538-.225.734.734,0,0,0-.538.225.719.719,0,0,0-.225.525.72.72,0,0,0,.225.525l.975.975a.719.719,0,0,0,.525.225.719.719,0,0,0,.525-.225l2.425-2.375a.734.734,0,0,0,.225-.537.734.734,0,0,0-.225-.537.734.734,0,0,0-.538-.225.734.734,0,0,0-.538.225ZM134-834a.968.968,0,0,0,.712-.287A.968.968,0,0,0,135-835a.968.968,0,0,0-.288-.713A.968.968,0,0,0,134-836H124a.968.968,0,0,0-.713.287A.968.968,0,0,0,123-835a.968.968,0,0,0,.287.713A.968.968,0,0,0,124-834Zm1,14a4.819,4.819,0,0,1-3.538-1.463A4.819,4.819,0,0,1,130-825a4.819,4.819,0,0,1,1.462-3.537A4.819,4.819,0,0,1,135-830a4.819,4.819,0,0,1,3.538,1.463A4.819,4.819,0,0,1,140-825a4.819,4.819,0,0,1-1.462,3.537A4.819,4.819,0,0,1,135-820Zm-13-20h14a1.926,1.926,0,0,1,1.413.588A1.926,1.926,0,0,1,138-838v6.675a6.1,6.1,0,0,0-1.462-.513A7.356,7.356,0,0,0,135-832H124a.968.968,0,0,0-.713.287A.968.968,0,0,0,123-831a.968.968,0,0,0,.287.713A.968.968,0,0,0,124-830h6.1a7.682,7.682,0,0,0-.788.925,6.9,6.9,0,0,0-.637,1.075H124a.968.968,0,0,0-.713.287A.968.968,0,0,0,123-827a.968.968,0,0,0,.287.713A.968.968,0,0,0,124-826h4.075a3.4,3.4,0,0,0-.062.487q-.012.237-.012.513a6.994,6.994,0,0,0,.288,2.013,6.962,6.962,0,0,0,.862,1.837.424.424,0,0,1-.262-.037.8.8,0,0,1-.238-.162l-.8-.8a.48.48,0,0,0-.35-.15.48.48,0,0,0-.35.15l-.8.8a.48.48,0,0,1-.35.15.48.48,0,0,1-.35-.15l-.8-.8a.48.48,0,0,0-.35-.15.48.48,0,0,0-.35.15l-.8.8a.48.48,0,0,1-.35.15.48.48,0,0,1-.35-.15l-.8-.8a.48.48,0,0,0-.35-.15.48.48,0,0,0-.35.15l-.8.8-.35.225V-838a1.926,1.926,0,0,1,.588-1.412A1.926,1.926,0,0,1,122-840Z"
                                        transform="translate(-120 840)" fill="#8e8fa2" />
                                </svg>
                                {{ 'companyAdminProfile.Commercial and tax registry data' | translate }}
                            </ng-template>
                            <div class="card">
                                <div class="card-head">
                                    <div class="card-title">
                                        <h4>{{ 'companyAdminProfile.Commercial and tax registry data' | translate }}
                                        </h4>
                                    </div>
                                    <!-- <div class="card-actions">
                                        <button mat-icon-button type="button" (click)="OnUpdateCompanyFiles()"
                                            *ngIf="permissions?.can_update" [matTooltip]="'general.Edit' | translate">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div> -->
                                </div>
                                <div class="card-content">
                                    <div class="info-data">
                                        <div class="icon-card">
                                            <img src="./assets/images/icons/commercial_reg_num_ic.svg" alt="">
                                        </div>
                                        <div>
                                            <label>{{'companies.Commercial registration No.' | translate}}</label>
                                            <div>{{CompanyDetails?.license_number}}</div>
                                        </div>
                                    </div>

                                    <div class="download-pdf d-flex">

                                        <app-files-viewer class="w-100" *ngIf="CompanyDetails?.license_file_object"
                                            [downloadWithApi]="true"
                                            [files]="[CompanyDetails?.license_file_object]"></app-files-viewer>
                                    </div>
                                    <div class="info-data">
                                        <div class="icon-card">
                                            <img src="./assets/images/icons/tax_number_ic.svg" alt="">
                                        </div>
                                        <div>
                                            <label>{{'companies.Tax Number' | translate}}</label>
                                            <div>{{CompanyDetails?.tax_number}}</div>
                                        </div>
                                    </div>

                                    <div class="download-pdf d-flex">
                                        <app-files-viewer class="w-100" *ngIf="CompanyDetails?.tax_number_object"
                                            [downloadWithApi]="true"
                                            [files]="[CompanyDetails?.tax_number_object]"></app-files-viewer>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>

                    <div class="theming-colors" *ngIf="permissions?.can_update">
                        <div class="content">
                            <p>{{ 'companyAdminProfile.Colors of mobile app and website' | translate }}</p>
                            <a (click)="OnUpdateCompanyColors()">{{ 'general.Edit' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<app-company-wallet-transactions *ngIf="selectedCompanyId" [id]="selectedCompanyId"
    [currentPoints]="CompanyDetails.balance.points"
    (close)="selectedCompanyId = null;"></app-company-wallet-transactions>