<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading" (click)="openConfirmDialog()"><svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </button>
        {{ 'vendors.Update Bank Account Data' | translate }}
    </div>
    <form [formGroup]="formGroup">
        <div class="dialog_content pb-0" mat-dialog-content>

            <div class="title">{{"vendors.Bank Account Data"|translate}}</div>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"vendors.bank_name"|translate}}</mat-label>
                    <input matInput type="text" maxlength="100" formControlName="bank_name">
                    <mat-error *ngIf="hasError('bank_name','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"vendors.bank_address"|translate}}</mat-label>
                    <input matInput type="text" maxlength="300" formControlName="bank_address">
                    <mat-error *ngIf="hasError('bank_address','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"vendors.bank_account_name"|translate}}</mat-label>
                    <input matInput type="text" maxlength="100" formControlName="bank_account_name">
                    <mat-error *ngIf="hasError('bank_account_name','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"vendors.bank_account_number"|translate}}</mat-label>
                    <input matInput type="text" maxlength="15" formControlName="bank_account_number">
                    <mat-error *ngIf="hasError('bank_account_number','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"vendors.iban"|translate}}</mat-label>
                    <input matInput type="text" maxlength="24" formControlName="iban">
                    <mat-error *ngIf="hasError('iban','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"vendors.swift_code"|translate}}</mat-label>
                    <input matInput type="text" maxlength="15" formControlName="swift_code">
                    <mat-error *ngIf="hasError('swift_code','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="dialog_actions" mat-dialog-actions>
        <button mat-button (click)="openConfirmDialog()" [disabled]="loading"
            class="btn-secondary">{{"general.Cancel"|translate}}</button>
        <button mat-button class="btn-primary" [disabled]="loading" [class.loading]="loading" (click)="saveRecord()">
            {{ 'vendorAdminProfile.Update' | translate }}</button>
    </div>
</div>