import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DirtyComponent } from '../../../../../../helpers/CanDeactivate/dirty-component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VendorProfileService } from '../../../services/vendor-profile.service';
import { TranslateService } from '@ngx-translate/core';
import { VendorsService } from '../../../../../vendors/services/vendors.service';
import Swal from 'sweetalert2';

export interface DialogData {
  details: any
}

@Component({
  selector: 'app-update-vendor-categories',
  templateUrl: './update-vendor-categories.component.html',
  styles: ``
})
export class UpdateVendorCategoriesComponent extends DirtyComponent {
  loading = false;
  dataLoading = false;
  isSubmitted = false;
  details!: any;
  // Categories
  categoriesLoading = false;
  categoriesList: any = [];
  selectedCategories: any[] = [];
  isCategoriesAlreadySelected = false;

  constructor(
    override dialogRef: MatDialogRef<UpdateVendorCategoriesComponent>,
    translate: TranslateService,
    private service: VendorProfileService,
    private vendorService: VendorsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    super(translate);
  }

  ngOnInit(): void {
    this.details = this.data?.details;
    this.getCategoriesByVendorId();
    if (this.details?.main_category.length) {
      this.isCategoriesAlreadySelected = true;
    }
  }

  getCategoriesByVendorId() {
    this.categoriesLoading = true;
    this.vendorService.GetCategoriesWithSubcategory_vendorID(this.data?.details?.id).subscribe({
      next: (res: any) => {
        this.categoriesLoading = false;
        this.categoriesList = res.data;
      },
      error: (error: any) => {
        this.categoriesLoading = false;
      }
    })
  }

  changeSelected(event: any) {
    console.log(event.length)
    if (event.length) {
      this.isCategoriesAlreadySelected = true;
    } else {
      this.isCategoriesAlreadySelected = false;
    }
  }

  saveRecord() {
    this.isSubmitted = true;
    let data = {
      categories_ids: this.selectedCategories.map((x: any) => x.id)
    }



    if (this.isCategoriesAlreadySelected) {
      this.loading = true;
      this.service.updateVendorCategories(this.data?.details?.id, data).subscribe((data) => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.updatedsuccessfully'),
          text: '',
          imageUrl: './assets/images/icons/success.svg',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
      });
    } else {
      Swal.fire({
        title: this.translate.instant('vendorAdminProfile.youmustselectedCategories'),
        text: '',
        icon: 'warning',
        confirmButtonText: this.translate.instant('swalMsg.Ok')
      });
    }
  }
}
