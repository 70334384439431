import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LookupsService {
  refreshList = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) {
  }

  GetMenu() {
    return this.http.get(`${environment.apiUrl}/get_menu`);
  }
}
