import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    // if (currentUser) {
    //   this.authenticationService.menuLoaded.subscribe((data): any => {
    //     if (data) {
    //       if (location.pathname !== '/') {
    //         let menuItems = [];
    //         let canAccess = false;
    //         menuItems = [...data];
    //         menuItems.push({link: '/login'});
    //         menuItems.push({link: '/admin/profile'});
    //         menuItems.forEach(item => {
    //           if (location.href.includes(item.link)) {
    //             canAccess = true;
    //           } else {
    //             if (item.childs && item.childs.length) {
    //               item.childs.forEach((child: any) => {
    //                 if (location.href.includes(child.link)) {
    //                   canAccess = true;
    //                 }
    //               });
    //             }
    //           }
    //         });
    //         if (canAccess) {
    //           return true;
    //         }
    //         this.router.navigate(['/admin']);
    //       }
    //     }
    //   });
    //   // authorised so return true
    //   return true;
    // }

    if (currentUser) {
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authenticationService.logout();
    this.router.navigate(['/']);
    return false;
  }
}
