import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vendor-contact-info',
  templateUrl: './vendor-contact-info.component.html',
  styles: ``
})
export class VendorContactInfoComponent {
  @Input() vendorDetails!: any;
}
