import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bank-account-data',
  templateUrl: './bank-account-data.component.html',
  styles: ``
})
export class BankAccountDataComponent {
  @Input() vendorDetails!: any;
}
