import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '../../../helpers/custom.validator';
import { RewardedService } from '../company-rewarded-points/services/rewarded.service';
import { ListingModel } from '../../companies/enum/company';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Patterns } from '../../../helpers/patterns';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-send-points',
  templateUrl: './send-points.component.html',
  styles: ``
})
export class SendPointsComponent {

  @ViewChild('stepper') private stepper!: MatStepper;
  balance!: any;

  formGroup!: FormGroup;
  secondFormGroup!: FormGroup;

  isSubmitted = false;

  loading = false;

  companyUsersLoading = false;
  filterModel: ListingModel = new ListingModel();
  companyUsers: any[] = [];
  companyUsersTotal!: number;

  pageIndex = 0;
  pageCount = 0;
  pageSize = 100;

  search = ''

  selectedUsers: any[] = []
  selectAll = false;

  numberOfEmployees: number = 0
  AllChecked: boolean = false;

  Subiscriptions: Subscription[] = [];
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private service: RewardedService,
    private translate: TranslateService) {
    // this.route.params.subscribe(params => {
    //   this.balance = params['balance'];
    // });
  }

  ngOnInit(): void {
    this.balance = localStorage.getItem('balance');
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      num_points: [
        null, [
          CustomValidator.noWhiteSpace,
          Validators.required,
          Validators.max(this.balance),
          Validators.min(1),
        ]
      ],
    })
    this.secondFormGroup = this.formBuilder.group({
      employees: [[]],
      for_all_employees: [false]
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  getNextPage() {
    if (this.pageIndex < this.pageCount - 1) {
      this.pageIndex += 1;
      this.getAll()
    }
  }

  getAll(isSearch?: boolean, isFirstCall?: boolean) {
    if (isSearch) {
      this.companyUsers = [];
      this.pageIndex = 0;
      document.getElementsByClassName('check-boxes-list')[0].scrollTop = 0;
    }
    this.companyUsersLoading = true;
    let model = {
      page: this.pageIndex + 1,
      perPage: this.pageSize,
      search: this.search
    }
    this.Subiscriptions.push(this.service.getRewardingPointsUsers(model).subscribe(
      (data: any) => {
        this.companyUsersLoading = false;
        if (data) {
          // if (this.selectAll) {
          //   data.data.list.forEach((e: any) => {
          //     if (!this.selectedUsers.includes(e.id)) {
          //       this.selectedUsers.push(e.id);
          //     }
          //   })
          // }
          if (isFirstCall) {
            let count = Math.floor(this.balance / this.formGroup.controls['num_points'].value)
            console.log(count, this.companyUsersTotal)
            this.numberOfEmployees = count;
          }

          this.companyUsersTotal = data.data.total;
          this.companyUsers = [...new Set([...this.companyUsers, ...data.data.list])];
          this.pageCount = Math.ceil(this.companyUsersTotal / this.pageSize);

          this.secondFormGroup.controls['employees'].setValue(this.selectedUsers);
        }
      },
      (error: any) => {
        this.companyUsersLoading = false;
        this.companyUsers = []
      }));
  }

  itemSelectionChange(event: any, id: any) {
    if (event) {
      if (!this.selectedUsers.includes(id)) {
        this.selectedUsers.push(id);
      }
    } else {
      this.selectAll = false;
      this.selectedUsers = this.selectedUsers.filter(item => item != id);
    }
  }

  selectAllUsers(event?: any) {
    if (event.checked) {
      this.companyUsers.map((user: any) => {
        if (!this.selectedUsers.includes(user.id)) {
          this.selectedUsers.push(user.id);
        }
      })
      this.secondFormGroup.controls['employees'].setValue(this.selectedUsers);
      // let newListIDs = this.companyUsers.map((e: any) => e.id);
      // this.selectedUsers = [...new Set([...this.selectedUsers, ...newListIDs])];
      // // this.secondFormGroup.controls['employees'].setValue(this.selectedUsers);
      // this.secondFormGroup.patchValue({ employees: [...this.selectedUsers] });

      if (this.secondFormGroup.controls['employees'].value.length == this.companyUsers?.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
    else {
      this.selectAll = false;
      this.secondFormGroup.controls['employees'].setValue([]);
      this.selectedUsers = []
    }
  }

  validateUsersSelected() {
    return !this.secondFormGroup.controls['employees'].value.length && this.isSubmitted;
  }

  validateUsersSelectedLength() {
    if (this.secondFormGroup.controls['employees'].value.length > this.numberOfEmployees) {
      return true;
    } else {
      return false;
    }
  }

  hideUserOption(item: any) {
    return new RegExp(this.search, 'i').test(item.name) === false
  }

  isAllUsersHidden() {
    return this.companyUsers.every(item => this.hideUserOption(item));
  }


  getBack() {
    this.location.back();
  }

  previous() {
    this.companyUsers = []
    this.stepper?.previous();
  }

  next() {
    this.companyUsers = []
    // this.isSubmitted = true;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.stepper?.next();
      this.getAll(false,true);

      // setTimeout(() => {
      //   let count = Math.floor(this.balance / this.formGroup.controls['num_points'].value)
      //   console.log(count, this.companyUsersTotal)
      //   this.numberOfEmployees = count;
      //   // if (this.companyUsersTotal > count) {
      //   //   this.numberOfEmployees = count;
      //   // } else {
      //   //   this.numberOfEmployees = this.companyUsersTotal;
      //   // }
      // }, 1000);
    } else {
      setTimeout(() => {
        let invalidElements = (document.querySelector('input.ng-invalid, select.ng-invalid, ng-select.ng-invalid input, mat-select.ng-invalid, ckeditor.ng-invalid') as HTMLElement);
        if (invalidElements) {
          invalidElements.focus()
        }
      }, 300);
    }
  }

  sendPoints() {
    this.isSubmitted = true;
    // this.secondFormGroup.patchValue({ employees: this.selectedUsers });

    if (this.formGroup.valid && this.secondFormGroup.valid && ((this.secondFormGroup.controls['for_all_employees'].value && this.companyUsersTotal < this.numberOfEmployees) || (!this.secondFormGroup.controls['for_all_employees'].value && !this.validateUsersSelectedLength()))) {

      this.loading = true;
      let data = {
        num_points: this.formGroup.controls['num_points'].value,
        employees: this.AllChecked ? [] : this.selectedUsers, //this.secondFormGroup.controls['employees'].value,
        for_all_employees: this.secondFormGroup.controls['for_all_employees'].value
      }

      this.Subiscriptions.push(this.service.sendPoints(data).subscribe((res) => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('rewardedPoints.Points distributed successfully'),
          text: '',
          imageUrl: './assets/images/icons/success.svg',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
        this.location.back();
      }, error => {
        this.loading = false;
      }));
    } else if (!this.secondFormGroup.controls['for_all_employees'].value) {
      if (!this.secondFormGroup.controls['employees'].value.length) {
        Swal.fire({
          title: this.translate.instant('rewardedPoints.you must select employees'),
          text: '',
          icon: 'warning',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
      }
    }
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.Subiscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
