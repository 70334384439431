<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading" (click)="openConfirmDialog()"><svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </button>
        {{"companyAdminProfile.Wallet Recharge"|translate}}
    </div>

    <div *ngIf='dataloading' class="dataLoading py-5">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>

    <ng-container *ngIf="!dataloading">

        <form [formGroup]="formGroup" id="WalletAmountForm" *ngIf="!PaymentDataLoaded">
            <div class="dialog_content pb-0" mat-dialog-content>

                <div class="hint mt-0"> {{"companyAdminProfile.Enter the amount to be charged"|translate}}</div>
                <div class="fields-group mb-4">
                    <mat-form-field class="arabic-form-field with-suffix-padding">
                        <mat-label>{{"companyAdminProfile.Amount to be charged"|translate}}</mat-label>
                        <input matInput appOnlynumber maxlength="6" min="1" formControlName="amount">
                        <span matSuffix>{{'general.SR'|translate}}</span>
                        <mat-error *ngIf="hasError('amount', 'required')">
                            {{ 'forms.Required' | translate }}</mat-error>
                        <mat-error *ngIf="hasError('amount','min')">
                            {{"form_message.lowest number"|translate :{num:1} }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="hint"> {{"companyAdminProfile.Choose the method to charging the wallet"|translate}}</div>

                <mat-radio-group aria-labelledby="example-radio-group-label"
                    [class.box-error]="hasError('payment_provider', 'required') && isSubmited" class="PaymentMethods"
                    formControlName="payment_provider">
                    @for (method of PaymentMethods;track method) {
                    <mat-radio-button class="example-radio-button" [value]="method.id">
                        <div class="payment_card">
                            <div class="image">
                                <img [src]="method.logo" alt="">
                            </div>
                            <h4>{{method.name}}</h4>
                        </div>
                    </mat-radio-button>
                    }

                </mat-radio-group>

                <div class="has-error" *ngIf="hasError('payment_provider', 'required') && isSubmited">
                    {{ 'forms.Required' | translate }}</div>
                <div class="hint">
                    {{"companyAdminProfile.hint_2"|translate}}
                </div>
                <div class="hint">
                    {{"companyAdminProfile.You will be directed to the payment gateway to complete the charging of your
                    wallet, thank you for your patience and understanding"|translate}}
                </div>





            </div>

            <div class="dialog_actions" mat-dialog-actions>
                <button mat-button type="button" [disabled]="loading" class="btn-secondary"
                    (click)="openConfirmDialog()">{{"general.Cancel"|translate}}</button>

                <button mat-button type="submit" [disabled]="loading" [class.loading]="loading" (click)="saveRecord()"
                    class="btn-primary">
                    {{"companyAdminProfile.Wallet Recharge"|translate}}
                </button>
            </div>
        </form>
        <!-- <div class="dialog_content pb-0" mat-dialog-content> -->
        <ng-container *ngIf="PaymentDataLoaded">
            <!-- <div class="p-3"> -->
            <iframe class="dialog_content p-0" [src]="PaymentSrc | safeUrl" width="100%" height="100%"
                frameborder="0"></iframe>
            <!-- </div> -->
        </ng-container>
        <!-- </div> -->

        <!-- <div class="dialog_content pb-0" mat-dialog-content>
            <ng-container *ngFor="let item of PaymentMethods">
                <div class="always-ltr">
                    <form *ngIf="!loading && SelectedPaymentProvider == item.id" id="eFinanceForm"
                        [action]="redirectLink" class="paymentWidgets" [attr.data-brands]="item.brands"></form>
                </div>

            </ng-container>

        </div> -->
    </ng-container>
</div>


<div class="mysr-form"></div>