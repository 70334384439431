<div class="dialog">


    <mat-dialog-content>
        <app-svg-custom-color [svgType]="'Small'" [svgMainColor]="data.color"
            [svgTextColor]="data.font_color"></app-svg-custom-color>

        <ng-container *ngIf="data.type == 'change'">
            <h2> {{"changeColor.Update mobile app and website colors"|translate}}</h2>


            <div class="record">
                <p> {{"changeColor.Color chosen"|translate}}</p>
                <div class="color">
                    <span class="text">{{data.color}}</span>
                    <span class="preview" [ngStyle]="{'background-color': data.color}"></span>
                </div>
                <p> {{"changeColor.to be the base color"|translate}}</p>
            </div>

            <div class="record">
                <p> {{"changeColor.And the color was chosen"|translate}}</p>
                <div class="color">
                    <span class="text">{{data.font_color}}</span>
                    <span class="preview" [ngStyle]="{'background-color': data.font_color}"></span>
                </div>
                <p>{{"changeColor.To be the color of the texts"|translate}}</p>
            </div>
        </ng-container>



        <ng-container *ngIf="data.type =='reset'">
            <h2> {{"changeColor.Reconfigure mobile app and website colors for default colors"|translate}}</h2>

        </ng-container>


    </mat-dialog-content>


    <div class="dialog_actions justify-content-center" mat-dialog-actions>
        <button mat-button type="button" (click)="dialogRef.close(false)" class="btn-secondary"
            [disabled]="loading">{{"general.Cancel"|translate}}</button>

        <button mat-button type="button" class="btn-primary" [class.loading]="loading" [disabled]="loading"
            (click)="OnConfirm()">
            {{(data.type=='change'?'changeColor.Confirm color update':"changeColor.Confirm color reconfiguration")|translate}}

        </button>
    </div>
</div>