<mat-stepper #stepper>
    <ng-template matStepperIcon="done">
        <mat-icon>done</mat-icon>
    </ng-template>
    <mat-step [stepControl]="formGroup" [completed]="formGroup.valid">
        <ng-template matStepLabel>{{ 'sendPoints.Points to be distributed' | translate }}</ng-template>
        <div class="form-page">
            <div class="form-content">

                <div class="d-flex align-items-center justify-content-between my-5">
                    <button mat-button class="btn-secondary" (click)="getBack()" [disabled]="loading">
                        {{ 'general.Cancel' | translate }}
                    </button>

                    <button mat-button class="btn-primary" (click)="next()" [disabled]="loading">
                        {{ 'sendPoints.next' | translate }}
                    </button>
                </div>
                <form [formGroup]="formGroup">
                    <div class="card">
                        <div class="row justify-content-center">
                            <div class="col-8">
                                <div class="withdrawal-points">

                                    <div class="current-balance">
                                        <h3>{{balance | number}}</h3>
                                        <p>{{ 'sendPoints.Your current balance' | translate }}</p>
                                    </div>

                                    <div class="input-points">
                                        <h6>{{ 'sendPoints.Enter the points to be distributed' | translate }}</h6>
                                        <mat-form-field class="english-form-field">
                                            <input matInput appOnlynumber maxlength="6" formControlName="num_points">
                                            <mat-error class="has-error arabic-form-field"
                                                *ngIf="hasError('num_points', 'max')">
                                                {{ 'rewardedPoints.Maximum points' | translate:{balance: balance} }}
                                            </mat-error>
                                            <mat-error class="font-14 arabic-form-field"
                                                *ngIf="hasError('num_points', 'required')">
                                                {{'forms.Required' | translate }}
                                            </mat-error>
                                            <mat-error class="arabic-form-field"
                                                *ngIf="hasError('num_points', 'pattern') || hasError('num_points', 'min')">
                                                {{ 'forms.Invalid value' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <p class="info-icon">
                                        {{ 'sendPoints.The above number of points will be distributed to each selected employee separately' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>{{ 'companyNotification.Select employees' | translate }}</ng-template>

        <div class="form-page">
            <div class="form-content">

                <div class="d-flex align-items-center justify-content-between my-5">
                    <button mat-button class="btn-secondary" (click)="previous()" [disabled]="loading">
                        {{ 'sendOffer.back' | translate }}
                    </button>

                    <button mat-button class="btn-primary" (click)="sendPoints()" [disabled]="loading"
                        [class.loading]="loading">
                        {{ 'sendPoints.Confirm the distribution of points' | translate }}
                    </button>
                </div>

                <div class="card p-0">
                    <div class="title">{{ 'companyNotification.Select employees' | translate }}</div>
                    <div class="hint-title">
                        <p>
                            {{'sendPoints.Points to be distributed' | translate }}
                            <span>{{formGroup.controls['num_points'].value}}</span>
                            {{'sendPoints.points per employee separately' | translate }} =
                            <span>{{numberOfEmployees|number}}</span>
                            {{'sendPoints.a maximum of employees (so that your current balance of points does not exceed)' | translate }}
                        </p>
                    </div>
                    <div class="card-content p-4">
                        <form [formGroup]="secondFormGroup">
                            <div class="page">
                                <mat-slide-toggle formControlName="for_all_employees" class="mb-3">
                                    {{ 'sendPoints.send to all employees' | translate}}
                                </mat-slide-toggle>
                                <div class="has-error"
                                    *ngIf="companyUsersTotal>numberOfEmployees && secondFormGroup.controls['for_all_employees'].value">
                                    {{'rewardedPoints.maximum employees' | translate:{no:numberOfEmployees} }}</div>
                                <div class="page-filters mb-0"
                                    [class.disabled]="secondFormGroup.controls['for_all_employees'].value">
                                    <div class="sort-item">
                                        <span class="count-page font-18 fontRegular grey-color">
                                            <span class="font-18 fontSemiBold text-color">
                                                {{selectedUsers.length}}</span>
                                            {{ "companyNotification.Selected employee" | translate }}
                                        </span>
                                    </div>
                                    <div class="filters-container">
                                        <div class="search-input with-input-group">
                                            <div class="form-group">
                                                <div class="input-group search-control">
                                                    <div class="input-group-prepend">
                                                        <button class="input-group-text" type="button"
                                                            (click)="getAll(true)">
                                                            <img src="./assets/images/icons/search.svg" alt="">
                                                        </button>
                                                    </div>
                                                    <input type="text" [(ngModel)]="search"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (keydown.enter)="getAll(true)"
                                                        [placeholder]="'companyNotification.Search for an employee' | translate">
                                                    <ng-container *ngIf="search">
                                                        <a (click)="search = '';getAll(true)" class="icon-input">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                height="12" viewBox="0 0 14 14">
                                                                <path id="ic_close"
                                                                    d="M211.746-746.861l-5.01,5.006a1.135,1.135,0,0,1-.827.356,1.094,1.094,0,0,1-.814-.356,1.1,1.1,0,0,1-.35-.819,1.127,1.127,0,0,1,.35-.807l5.01-5.018-5.01-5.018a1.1,1.1,0,0,1-.343-.807,1.145,1.145,0,0,1,.343-.819,1.093,1.093,0,0,1,.814-.356,1.155,1.155,0,0,1,.827.343l5.01,5.018,5.023-5.018a1.113,1.113,0,0,1,.814-.343,1.134,1.134,0,0,1,.827.356,1.122,1.122,0,0,1,.337.819,1.127,1.127,0,0,1-.35.807l-5.01,5.018,5.01,5.018a1.1,1.1,0,0,1,.343.807,1.146,1.146,0,0,1-.343.819,1.094,1.094,0,0,1-.814.356,1.094,1.094,0,0,1-.814-.356Z"
                                                                    transform="translate(-204.746 755.5)"
                                                                    fill="#808080" />
                                                            </svg>
                                                        </a>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div [class.disabled]="secondFormGroup.controls['for_all_employees'].value">

                                <div class="row justify-content-center" *ngIf="!search">
                                    <div class="col-12 p-0">
                                        <div class="check-box-normal">
                                            <mat-checkbox (change)="selectAllUsers($event)"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="selectAll"
                                                [checked]="(secondFormGroup.controls['employees'].value.length == companyUsersTotal)">
                                                {{'notifications.Select All' | translate }}
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-12 pb-0">
                                        <div class="position-relative mb-2" [hidden]="companyUsersLoading">
                                            <div class="check-boxes-list small-empty" infiniteScroll
                                                [scrollWindow]="false" [infiniteScrollDistance]="1"
                                                [infiniteScrollThrottle]="pageCount" (scrolled)="getNextPage()"
                                                [fromRoot]="true">
                                                <div *ngIf="companyUsers.length; else dataEmpty">
                                                    <mat-selection-list formControlName="employees"
                                                        [required]="!secondFormGroup.controls['for_all_employees'].value"
                                                        (selectionChange)="validateUsersSelectedLength()">
                                                        <div *ngFor="let user of companyUsers">
                                                            <div>
                                                                <mat-list-option class="check" [value]="user.id"
                                                                    checkboxPosition="before" (selectedChange)="itemSelectionChange($event, user.id)">
                                                                    <div class="name-with-image mb-0">
                                                                        <div class="image-circle">
                                                                            <img *ngIf="user.image" [src]="user.image"
                                                                                (error)='user.image = ""'>
                                                                            <span *ngIf="!user.image"
                                                                                class="font-14 fontRegular">
                                                                                {{ user.name| shortName }}
                                                                            </span>
                                                                        </div>
                                                                        <div class="name">
                                                                            <h5 class="font-18 fontRegular mb-0">
                                                                                {{user.name}}
                                                                            </h5>
                                                                            <a class="font-14 fontMedium grey-color">
                                                                                {{user.department_name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </mat-list-option>
                                                            </div>
                                                        </div>
                                                    </mat-selection-list>
                                                </div>
                                                <ng-template #dataEmpty>
                                                    <empty-state
                                                        [title]='"general.No Data Found" | translate'></empty-state>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-12 pb-0">
                                                <mat-error class="font-14"
                                                    *ngIf="validateUsersSelected() && !secondFormGroup.controls['for_all_employees'].value">
                                                    {{'forms.Required' | translate }}
                                                </mat-error>
                                                <mat-error class="font-14"
                                                    *ngIf="validateUsersSelectedLength() && !secondFormGroup.controls['for_all_employees'].value">
                                                    {{'rewardedPoints.maximum employees' | translate:{no:
                                                    numberOfEmployees}
                                                    }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div *ngIf='companyUsersLoading' class="dataLoading py-5 d-block m-auto">
                                            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </mat-step>
</mat-stepper>
