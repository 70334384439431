import { Component, Inject, OnInit } from '@angular/core';
import { DirtyComponent } from '../../../../helpers/CanDeactivate/dirty-component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidator } from '../../../../helpers/custom.validator';
import { CompanyProfileService } from '../services/company-profile.service';
import { ConfigService } from '../../../../helpers/config.service';

@Component({
  selector: 'app-update-company-files',
  templateUrl: './update-company-files.component.html',
  styles: ``
})
export class UpdateCompanyFilesComponent extends DirtyComponent implements OnInit {

  override formGroup!: FormGroup<any>;
  loading: boolean = false;
  constructor(override dialogRef: MatDialogRef<UpdateCompanyFilesComponent>,
    translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _companyProfileService: CompanyProfileService,
    private _config: ConfigService) {
    super(translate)

  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      license_number: [this.data?.license_number, [CustomValidator.noWhiteSpace, Validators.required, Validators.maxLength(20)]],
      license_attachment: [null],
      tax_number: [this.data?.tax_number, [CustomValidator.noWhiteSpace, Validators.required, Validators.maxLength(20)]],
      tax_attachment: [null],
    });
  }

  OnSave() {
    this.loading = true;

    let formData = new FormData();
    for (const key in this.formGroup.value) {
      const element = this.formGroup.value[key];
      if (element) {
        formData.append(key, element)

      }
    }

    formData.append("_method", "PUT")
    this.formGroup.value.tax_attachment === undefined ? formData.append("delete_tax_attachment", '1') : null;
    this.formGroup.value.license_attachment === undefined ? formData.append("delete_license_attachment", '1') : null;

    this._companyProfileService.UpdateCompanyFiles(this.data.id, formData).subscribe({
      next: (res: any) => {
        this.loading = false;
        this._config.SusseccMessage(this._config._translate.instant("swalMsg.updatedsuccessfully"));
        this.dialogRef.close(true);
      },
      error: (error: any) => {
        this.loading = false;
      },
    })
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }


  OnSelectLicense_attachment(files: any) {
    this.formGroup.patchValue({ license_attachment: files[0] });
    this.formGroup.controls['license_attachment'].markAsDirty();

  }
  OnSelectTax_attachment(files: any) {
    this.formGroup.patchValue({ tax_attachment: files[0] });
    this.formGroup.controls['tax_attachment'].markAsDirty();

  }

}
