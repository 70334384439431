<!-- <div class="main-wrapper d-flex align-items-center justify-content-center">
  <button mat-flat-button class="lang-btn" (click)="changeLanguage()">
    {{ 'general.language' | translate }}
  </button>
  <div class="card">
    <div class="logo-container">
      <img src="./assets/images/logos/logo.svg" alt="">
    </div>
    <div class="card-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div> -->

<div class="main-wrapper">
  <button mat-flat-button class="lang-btn" (click)="changeLanguage()">
    {{ 'general.language' | translate }}
  </button>
  <section>

    <div class="side_image">
      <div class="content">
        <img src="./assets/images/over_cover.png" alt="" srcset="">
        <p> {{("login.Raising the level of employee requirements"|translate)|titlecase}}</p>
      </div>
    </div>

    <div class="form">
      <router-outlet></router-outlet>
    </div>

  </section>
</div>