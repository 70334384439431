import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkingHoursFormComponent } from '../../../../../shared/working-hours-form/working-hours-form.component';
import { WorkingHours } from '../../../../vendors/models/workingHours';
import { WorkingHoursService } from '../../../../vendors/services/working-hours.service';

@Component({
  selector: 'app-vendor-working-hours',
  templateUrl: './vendor-working-hours.component.html',
  styles: ``
})
export class VendorWorkingHoursComponent {
  @Input() vendorDetails!: any;

  recordsData!: WorkingHours;
  dataLoading = false;

  mainBranchId!: any;

  constructor(private dialog: MatDialog,
    private workingHoursService: WorkingHoursService
  ) { }

  ngOnInit(): void {
    // this.getAllBranchWorkingHours();
  }

  getAllBranchWorkingHours() {
    this.dataLoading = true;
    this.workingHoursService.getAll(this.mainBranchId).subscribe((res) => {
      this.recordsData = res;
      this.dataLoading = false;
    })
  }

  configWorkingHours() {
    const dialogRef = this.dialog.open(WorkingHoursFormComponent, {
      width: '540px'
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
      }
    })
  }
}
