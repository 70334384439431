import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vendor-addresses',
  templateUrl: './vendor-addresses.component.html',
  styles: ``
})
export class VendorAddressesComponent {

  @Input() vendorDetails!: any;
  mapOptions!: google.maps.MapOptions
  Marker!: google.maps.MarkerOptions

  ngOnChanges(changes: any): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.vendorDetails) {
      this.Marker = {
        position: {
          lat: Number(this.vendorDetails?.map_lat),
          lng: Number(this.vendorDetails?.map_lang)
        },
        clickable: true,
        label: { text: this.vendorDetails?.city_name + (this.vendorDetails?.address ? (',' + this.vendorDetails?.address) : ''), className: 'map_lable' }
      }

      this.mapOptions = {
        center: {
          lat: Number(this.vendorDetails?.map_lat),
          lng: Number(this.vendorDetails?.map_lang)
        },
        mapId: 'customMap4',
        scrollwheel: true,
        disableDoubleClickZoom: true,
        mapTypeId: 'roadmap',
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false
      };
    }
  }
}
