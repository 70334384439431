import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { CompanyProfileService } from '../../services/company-profile.service';
import { ConfigService } from '../../../../../helpers/config.service';

@Component({
  selector: 'app-confirm-colors',
  templateUrl: './confirm-colors.component.html',
  styles: ``
})
export class ConfirmColorsComponent implements OnInit {

  loading: boolean = false;
  CompanyId: any
  constructor(public dialogRef: MatDialogRef<ConfirmColorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _companyProfileService: CompanyProfileService,
    private _config: ConfigService) {

  }

  ngOnInit(): void {
    // this.CompanyId = this._authService.currentUserValue?.company_id;
  }


  OnConfirm() {
    this.loading = true;
    this._companyProfileService.UpdateColors(this.data.company_id, this.data).subscribe({
      next: (res) => {
        this.loading = false;
        this.dialogRef.close(true);
        if (this.data.type === "change") {
          this._config.SusseccMessage(this._config._translate.instant("changeColor.Mobile app and website colors changed successfully"))

        } else {
          this._config.SusseccMessage(this._config._translate.instant("changeColor.Mobile app and website colors have been successfully reconfigured for default colors"))
        }
      },
      error: (err) => {
        this.loading = false;
      }
    })
  }

}
