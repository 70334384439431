<div class="small-empty">
    <div class="col-8 p-0">
        <ng-container *ngIf="vendorDetails?.main_category; else dataEmpty">
            <mat-accordion class="Custom-accordion" *ngFor="let mainCategory of vendorDetails?.main_category; let i = index">
                <mat-expansion-panel (opened)="getSubCategories(mainCategory.id, i)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="name-with-image">
                                <div class="image-square">
                                    <img *ngIf="mainCategory.image" [src]="mainCategory.image"
                                        (error)='mainCategory.image = ""'>
                                    <span *ngIf="!mainCategory.image">
                                        {{ mainCategory.name | shortName }}</span>
                                </div>
                                <div class="name">
                                    <h6 class="mb-2">{{ mainCategory.name }}</h6>
                                    <h5>{{ mainCategory.count_sub_category }}
                                        <span>
                                            {{ "vendors.Sub-categories" | translate }}
                                        </span>
                                    </h5>
                                </div>
                            </div>

                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div *ngIf='vendorDetails?.main_category[i].loading' class="dataLoading">
                        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
                    </div>
                    <div *ngIf='!vendorDetails.main_category[i].loading && !vendorDetails.main_category[i].subCategoris?.length'>

                        <p class="text-center font-14 fontRegular grey-color">
                            {{ 'general.No Data Found' | translate }}
                        </p>
                    </div>
                    <div *ngIf="vendorDetails?.main_category[i]?.subCategoris?.length">
                        <ul>
                            <li *ngFor="let subCategory of vendorDetails?.main_category[i]?.subCategoris">
                                <span>{{subCategory?.name}}</span>
                            </li>
                        </ul>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
        <ng-template #dataEmpty>
            <empty-state [title]='"general.No Data Found" | translate'></empty-state>
        </ng-template>
    </div>
</div>