<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading"  (click)="openConfirmDialog()" ><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="ic_close"
                d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                transform="translate(-204.746 755.5)" fill="#999" />
        </svg>
    </button>
        {{"userProfile.Change the Password"|translate}}
    </div>
    <div class="dialog_content" mat-dialog-content>
        <form [formGroup]="formGroup" id="updatePasswordForm" (ngSubmit)="onSave()">
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"userProfile.The Current Password"|translate}}</mat-label>
                    <input class="Passowrd" matInput [type]="hideOld ? 'password' : 'text'" formControlName="old_password" maxlength="50">
                    <a mat-icon-button matSuffix type="button" (click)="hideOld = !hideOld" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideOld">
                        <!-- <img src="/assets/images/icons/password_eye.svg" alt="eye"> -->
                        <mat-icon>{{hideOld ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>
                    <mat-error *ngIf="hasError('old_password','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                    <!-- <mat-error *ngIf="hasError('old_password','pattern')">
                        {{"form_message.Invalid password pattern"|translate}}</mat-error> -->
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"resetPassword.The new password"|translate}}</mat-label>
                    <input class="Passowrd" matInput [type]="hide ? 'password' : 'text'" formControlName="password" maxlength="50">
                    <a mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <!-- <img src="/assets/images/icons/password_eye.svg" alt="eye"> -->
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>
                    <mat-error *ngIf="hasError('password','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                    <!-- <mat-error *ngIf="hasError('password','pattern')">
                        {{"form_message.Invalid password pattern"|translate}}</mat-error> -->
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"resetPassword.Confirm the new password"|translate}}</mat-label>
                    <input class="Passowrd" matInput [type]="hideConfirm ? 'password' : 'text'" formControlName="password_confirmation"
                        maxlength="100">
                    <a mat-icon-button matSuffix type="button" (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideConfirm">
                        <!-- <img src="/assets/images/icons/password_eye.svg" alt="eye"> -->
                        <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>
                    <mat-error *ngIf="hasError('password_confirmation','notMatch')">
                        {{"form_message.Password is not match"|translate}}</mat-error>

                    <mat-error *ngIf="hasError('password_confirmation','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </form>

        <div class="reset_password">
            <div class="title">{{"resetPassword.The new password must be"|translate}}:</div>
            <div class="list">
                <ul [class.isSubmitted]="isSubmitted">
                    <li [class.valid]="isEnglishOnlyValid">{{"resetPassword.Be on English language only"|translate}}</li>
                    <li [class.valid]="isMinLengthValid">{{"resetPassword.Consists of at least 8 characters"|translate}}</li>
                    <li [class.valid]="isLowerCaseValid">{{"resetPassword.Consists of at least 7 small letters (A-Z)"|translate}}</li>
                    <li [class.valid]="isUpperCaseValid">{{"resetPassword.Consists of at least 1 Capital letter (A-Z)"|translate}}</li>
                    <li [class.valid]="isNumberValid">{{"resetPassword.Consists of at least 1 Number"|translate}}</li>
                    <li [class.valid]="isSymbolValid">{{"resetPassword.Consists of at least 1 symbol"|translate}}
                        {{symbols}}</li>
                </ul>
            </div>
        </div>


    </div>
    <div class="dialog_actions" mat-dialog-actions>

        <button mat-button (click)="openConfirmDialog()" type="button"[disabled]="loading"
            class="btn-secondary">{{"resetPassword.Cancel"|translate}}</button>
        <button mat-button [ngClass]="{'loading': loading}" [disabled]="loading" class="btn-primary" type="submit"
            form="updatePasswordForm">
            {{"resetPassword.Save the password"|translate}}
        </button>
    </div>
</div>
