import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../services/authentication.service';
import { NavigationService } from '../../../services/navigation.service';
import { Router } from '@angular/router';
import { VendorProfileService } from './services/vendor-profile.service';
import { UpdateAddressFormComponent } from './components/vendor-addresses/update-address-form/update-address-form.component';
import { UpdateSocialInfoComponent } from './components/vendor-contact-info/update-social-info/update-social-info.component';
import { UpdateVendorCategoriesComponent } from './components/vendor-categories/update-vendor-categories/update-vendor-categories.component';
import { RequestWithdrawalPointsForVendorComponent } from '../request-withdrawal-points-for-vendor/request-withdrawal-points-for-vendor.component';
import { ClaimedPointOfferService } from '../../../shared/claim-point-offer/services/claimPointOffer.service';
import { UpdateBankAccountComponent } from './components/bank-account-data/update-bank-account/update-bank-account.component';

@Component({
  selector: 'app-vendor-admin-profile',
  templateUrl: './vendor-admin-profile.component.html',
  styles: ``
})
export class VendorAdminProfileComponent {
  dataLoading = false;

  showSearch = false;
  showFilter = false;

  pageIndex = 0;
  pageSize = 10;
  pageCount = 0;
  recordsData!: any | null;
  filterModel: any;

  vendorDetails: any;
  permissions: any
  selectedVendorId!: any
  constructor(private dialog: MatDialog,
    private router: Router,
    private navService: NavigationService,
    private service: VendorProfileService,
    private authService: AuthenticationService,
    private _ClaimedPointOfferService: ClaimedPointOfferService) { }


  ngOnInit(): void {
    this.getVendorDetails();
    setTimeout(() => {
      this.navService.sidebarState.sidenavOpen = false;
    }, 10);

    this._ClaimedPointOfferService.RefreshWallet.subscribe((res: boolean) => {
      if (res) {
        this.getVendorDetails();
      }
    })
  }



  requestWithdrawalRequest() {
    const dialogRef = this.dialog.open(RequestWithdrawalPointsForVendorComponent, {
      width: '540px',
      data: { balance: this.vendorDetails.balance.balance }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })

  }

  getVendorDetails() {
    let vendorId = this.authService.currentUserValue?.vendor_id || ''
    this.dataLoading = true;
    this.service.GetVendorProfile(vendorId).subscribe({
      next: (res: any) => {
        this.dataLoading = false;
        this.vendorDetails = res.data;
        this.permissions = res.permissions
      },
      error: (error: any) => {
        this.dataLoading = false;
      }
    })
  }


  updateVendorAddress() {
    const dialogRef = this.dialog.open(UpdateAddressFormComponent, {
      width: '540px',
      data: { details: this.vendorDetails }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })
  }

  updateVendorContactInfo() {
    const dialogRef = this.dialog.open(UpdateSocialInfoComponent, {
      width: '540px',
      data: { details: this.vendorDetails }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })
  }

  updateVendorCategories() {
    const dialogRef = this.dialog.open(UpdateVendorCategoriesComponent, {
      width: '540px',
      data: { details: this.vendorDetails }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })
  }

  updateBankAccountDetails() {
    const dialogRef = this.dialog.open(UpdateBankAccountComponent, {
      width: '540px',
      data: { details: this.vendorDetails }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })
  }
}
