import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from './config.service';

@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {
  constructor(private _config: ConfigService) { }

  transform(dateString: string): string {
    const givenDate = new Date(dateString);
    const now = new Date();
    // debugger
    // Subtract 24 hours from the current date to check if the given date is yesterday
    now.setDate(now.getDate() - 1);
    // debugger
    // Calculate the difference between the current date and the given date in hours
    const diff = (now.getTime() - givenDate.getTime()) / (1000 * 60 * 60);

    // Check if the given date is today or yesterday
    if (diff < 1) {
      return this._config._translate.instant('general.today');
    } else if (diff < 24) {
      return this._config._translate.instant('general.yesterday');
    }

    // If the given date is older than yesterday, return the original date string
    return '';
  }

}
