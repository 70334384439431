import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ConfigService } from '../../../../helpers/config.service';
import { TranslateService } from '@ngx-translate/core';
import { DirtyComponent } from '../../../../helpers/CanDeactivate/dirty-component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Patterns } from '../../../../helpers/patterns';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styles: ``
})
export class UpdatePasswordComponent extends DirtyComponent implements OnInit {


  hideOld: boolean = true;
  hideConfirm: boolean = true;
  hide: boolean = true;
  symbols: string = `(! , @, #, $, %, &, *, +, =, {, }, ?, <, >, ", ')`


  override formGroup!: FormGroup;
  loading: boolean = false;
  isSubmitted = false;

  constructor(translate: TranslateService,
    override dialogRef: MatDialogRef<UpdatePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _profileSerivce: ProfileService,
    private _config: ConfigService) {
    super(translate)
  }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      old_password: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      password: new FormControl('', [Validators.required, Validators.pattern(Patterns.password), Validators.pattern(Patterns.enTxtWithSpecial), Validators.maxLength(50)]),
      password_confirmation: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    })
    this.confirmPasswordValidation();
  }


  onSave() {
    this.isSubmitted = true;
    if (this.formGroup.invalid) { return }
    this.loading = true;
    let model = this.formGroup.value;
    this._profileSerivce.UpdatePassword(model).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.dialogRef.close(true);
        this._config.SusseccMessage(this._config._translate.instant("userProfile.The Password had been changed"))
      },
      error: (error: any) => {
        this.loading = false;
        // let msg = error.errors.map((err: any) => err.massage + '<br>')
        // Swal.fire({
        //   title: this.translate.instant('swalMsg.Failed'),
        //   html: msg,
        //   icon: 'error',
        //   confirmButtonText: this.translate.instant('swalMsg.Ok'),
        // })
      },
    })
    // }

  }


  confirmPasswordValidation() {
    this.formGroup.controls['password_confirmation'].valueChanges.subscribe(data => {
      const pass = this.formGroup.get('password')?.value;
      if (pass !== data && pass.length && data.length) {
        this.formGroup.get('password_confirmation')?.setErrors({ notMatch: true });
      }
    })

    this.formGroup.controls['password'].valueChanges.subscribe(data => {
      const password_confirmation = this.formGroup.get('password_confirmation')?.value;
      if (password_confirmation == data) {
        this.formGroup.get('password_confirmation')?.setErrors(null);
      }
      else if (data.length && password_confirmation?.length && password_confirmation !== data) {
        this.formGroup.get('password_confirmation')?.setErrors({ notMatch: true });
      }
    })

  }


  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }



  get isEnglishOnlyValid() {
    return this.formGroup.controls['password'].value.match(Patterns.enTxtWithSpecial);
  }

  get isMinLengthValid() {
    return this.formGroup.controls['password'].value.length >= 8;
  }

  get isLowerCaseValid() {
    return this.formGroup.controls['password'].value.match(/[a-z]/);
  }

  get isUpperCaseValid() {
    return this.formGroup.controls['password'].value.match(/[A-Z]/);
  }

  get isNumberValid() {
    return this.formGroup.controls['password'].value.match(/\d/);
  }

  get isSymbolValid() {
    return this.formGroup.controls['password'].value.match(/[!@#$%&*+=?{}<>"']/);
  }
}
