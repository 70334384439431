import { Component, Inject, OnInit } from '@angular/core';
import { DirtyComponent } from '../../../../helpers/CanDeactivate/dirty-component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../users/services/user.service';
import Swal from 'sweetalert2';
import { CustomValidator } from '../../../../helpers/custom.validator';
import { Patterns } from '../../../../helpers/patterns';
import { ProfileService } from '../../services/profile.service';
import { AuthenticationService } from '../../../../services/authentication.service';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styles: ``
})
export class UpdateProfileComponent extends DirtyComponent implements OnInit {
  override formGroup!: FormGroup;
  loading = false;
  dataLoading = false;
  isSubmitted = false;

  details!: any;

  rolesLoading = false;
  roles!: any | null;

  isUpdate: boolean = this.data.details ? true : false;
  SelectedImage: any

  currentUserType!: any;
  constructor(override dialogRef: MatDialogRef<UpdateProfileComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    translate: TranslateService,
    private authenticationService: AuthenticationService,
    private _profileService: ProfileService) {
    super(translate);
  }

  ngOnInit(): void {
    this.currentUserType = this.authenticationService.currentUserValue?.type;
    this.buildForm();
    this.getDetails();
  }


  getDetails() {
    this.dataLoading = true;
    this._profileService.viewUser(this.data.id).subscribe((data) => {
      this.dataLoading = false;
      this.details = data.data;
      this.bindDataToUpdate();
    })
  }


  buildForm() {
    this.formGroup = this.formBuilder.group({
      full_name: [
        null, [
          CustomValidator.noWhiteSpace,
          Validators.maxLength(100)
        ]
      ],
      phone_number: [null, [
        CustomValidator.noWhiteSpace,
        Validators.maxLength(10)
      ]],
      email: [null, [
        CustomValidator.noWhiteSpace,
        Validators.maxLength(100),
        Validators.pattern(Patterns.email)
      ]],
      role_id: [null],
      job_title: []
    });
  }


  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }


  bindDataToUpdate() {
    this.formGroup.patchValue({
      full_name: this.details.full_name,
      phone_number: this.details.phone_number ? this.details.phone_number?.slice(1) : '',
      email: this.details.email,
      role_id: this.details.role.id,
      job_title: this.details.job_title
    });
  }


  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;

      const formData = new FormData();
      let model = { ...this.formGroup.value }
      model.phone_number = '5' + model.phone_number;
      model.image = this.SelectedImage;

      for (const key in model) {
        if (model[key])
          formData.append(key, model[key]);
      }

      this._profileService.UpdateMyProfile(formData).subscribe((res) => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.updatedsuccessfully'),
          text: '',
          imageUrl: './assets/images/icons/success.svg',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
        this.dialogRef.close(true);
        this.authenticationService.currentUserSubject.next(res.data);
        localStorage.setItem('authUser', JSON.stringify(res.data));

      }, error => {
        this.loading = false;
        // Swal.fire({
        //   title: this.translate.instant('swalMsg.Failed'),
        //   text: error.errors[0].massage,
        //   icon: 'error',
        //   confirmButtonText: this.translate.instant('swalMsg.Ok'),
        // })
      });
    } else {
      setTimeout(() => {
        let invalidElements = (document.querySelector('input.ng-invalid, select.ng-invalid, ng-select.ng-invalid input, mat-select.ng-invalid, ckeditor.ng-invalid') as HTMLElement);
        if (invalidElements) {
          invalidElements.focus()
        }
      }, 300);
    }
  }


  OnSelectProfileImage(Image: any) {

    this.SelectedImage = Image;

  }

}
