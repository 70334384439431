<p class="font-14 fontRegular text-color mb-0">{{"vendors.Commercial and Tax registration number"|translate}}</p>
<div class="info-data">
    <div class="icon-card">
        <img src="./assets/images/icons/commercial_reg_num_ic.svg" alt="">
    </div>
    <div>
        <label>{{'companies.Commercial registration No.' | translate}}</label>
        <div>{{vendorDetails?.license_number}}</div>
    </div>
</div>

<div class="download-pdf d-flex" *ngIf="vendorDetails?.license_file_object">
    <app-files-viewer class="w-100" *ngIf="vendorDetails?.license_file_object" [downloadWithApi]="true"
        [files]="[vendorDetails?.license_file_object]"></app-files-viewer>
</div>

<div class="info-data">
    <div class="icon-card">
        <img src="./assets/images/icons/tax_number_ic.svg" alt="">
    </div>
    <div>
        <label>{{'companies.Tax Number' | translate}}</label>
        <div>{{vendorDetails?.tax_number}}</div>
    </div>
</div>

<div class="download-pdf d-flex" *ngIf="vendorDetails?.tax_number_object">
    <app-files-viewer class="w-100" *ngIf="vendorDetails?.tax_number_object" [downloadWithApi]="true"
        [files]="[vendorDetails?.tax_number_object]"></app-files-viewer>
</div>