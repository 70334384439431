import { Component, Inject } from '@angular/core';
import { DirtyComponent } from '../../../../../../helpers/CanDeactivate/dirty-component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { VendorProfileService } from '../../../services/vendor-profile.service';
import Swal from 'sweetalert2';

export interface DialogData {
  details: any
}

@Component({
  selector: 'app-update-bank-account',
  templateUrl: './update-bank-account.component.html',
  styles: ``
})
export class UpdateBankAccountComponent extends DirtyComponent {
  loading = false;
  dataLoading = false;
  override formGroup!: FormGroup;
  isSubmitted = false;
  details!: any;

  constructor(
    override dialogRef: MatDialogRef<UpdateBankAccountComponent>,
    translate: TranslateService,
    private formBuilder: FormBuilder,
    private service: VendorProfileService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    super(translate);
  }

  ngOnInit(): void {
    this.details = this.data?.details;
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      bank_name: [this.details ? this.details?.bank_name : null, [Validators.maxLength(100)]],
      bank_address: [this.details ? this.details?.bank_address : null, [Validators.maxLength(300)]],
      bank_account_name: [this.details ? this.details?.bank_account_name : null, [Validators.maxLength(100)]],
      bank_account_number: [this.details ? this.details?.bank_account_number : null, [Validators.maxLength(15)]],
      iban: [this.details ? this.details?.iban : null, [Validators.maxLength(24)]],
      swift_code: [this.details ? this.details?.swift_code : null, [Validators.maxLength(15)]]
    })
  }
  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;
      let model = { ...this.formGroup.value }
      // if (model.vendor_phone_number) {
      //   model.vendor_phone_number = '05' + model.vendor_phone_number;
      // }
      this.service.updateBankAccountData(this.details.id, model).subscribe((data) => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.updatedsuccessfully'),
          text: '',
          imageUrl: './assets/images/icons/success.svg',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
      });
    }
  }
}
