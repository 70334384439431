import { Component, Input } from '@angular/core';
import { CategoriesService } from '../../../../categories/services/categories.service';

@Component({
  selector: 'app-vendor-categories',
  templateUrl: './vendor-categories.component.html',
  styles: ``
})
export class VendorCategoriesComponent {
  @Input() vendorDetails!: any;
  constructor(private categoryService: CategoriesService) { }
  ngOnInit(): void {
  }

  getSubCategories(parentId: any, i: any) {
    if (this.vendorDetails?.main_category[i]?.subCategoris) {
      return
    }
    this.vendorDetails.main_category[i].loading = true;
    this.categoryService.getVendorSubCategories(this.vendorDetails.id, parentId).subscribe((data: any) => {
      this.vendorDetails.main_category[i].subCategoris = data.data;
      this.vendorDetails.main_category[i].loading = false;

    })
  }
}
