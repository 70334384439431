<div class="main-wrapper authLayout">
    <img class="cover_img" src="/assets/images/basicLayoutCover.png" alt="Cover">
    <nav>
        <a href="/"><img src="/assets/images/logos/logo_white.svg" alt="Offer-X Logo"></a>
        <a class="language_btn" (click)="changeLanguage()">
            {{"general.EN"|translate}}
        </a>
    </nav>
    <section>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </section>

    <footer>
        <div class="text-center">
            © {{"general.All rights reserved"|translate}} 2024
        </div>
    </footer>
</div>