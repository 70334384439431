import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestWithdrawalPointsComponent } from '../request-withdrawal-points/request-withdrawal-points.component';
import { UpdateCompanyColorsComponent } from './update-company-colors/update-company-colors.component';
import { ChargeWalletComponent } from './charge-wallet/charge-wallet.component';
import { ConfirmColorsComponent } from './update-company-colors/confirm-colors/confirm-colors.component';
import { CompanyDataInterface } from './models/companyProfile';
import { CompanyProfileService } from './services/company-profile.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { UpdateCompanyFilesComponent } from './update-company-files/update-company-files.component';
import { RewardedService } from '../company-rewarded-points/services/rewarded.service';
import { Router } from '@angular/router';
import { UpdateCompanyInfoComponent } from './update-company-info/update-company-info.component';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-company-admin-profile',
  templateUrl: './company-admin-profile.component.html',
  styles: ``
})
export class CompanyAdminProfileComponent implements OnInit {
  dataLoading = false;

  showSearch = false;
  showFilter = false;

  pageIndex = 0;
  pageSize = 10;
  pageCount = 0;
  recordsData!: any | null;
  filterModel: any;

  CompanyDetails: any;
  permissions: any
  selectedCompanyId!: any
  constructor(private dialog: MatDialog,
    private _companyProfileService: CompanyProfileService,
    private _authService: AuthenticationService,
    private _rewardedService: RewardedService,
    private router: Router,
    private navService: NavigationService) { }


  ngOnInit(): void {
    this.GetCompanyData();
    setTimeout(() => {
      this.navService.sidebarState.sidenavOpen = false;
    }, 10);
  }


  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.search) {
        this.GetCompanyData();
      }
    }
  }

  resetSearch() {
    this.filterModel.search = '';
    this.GetCompanyData();
  }

  // getAll(event: any) {

  // }


  requestWithdrawalRequest() {
    const dialogRef = this.dialog.open(RequestWithdrawalPointsComponent, {
      width: '540px',
      data: { balance: this.CompanyDetails.balance.points }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.GetCompanyData()
      }
    })

  }
  OnUpdateCompanyColors() {
    const dialog = this.dialog.open(UpdateCompanyColorsComponent, {
      width: '1300px',
      data: this.CompanyDetails
    })
    dialog.afterClosed().subscribe((res: any) => {

      if (res) {

        const dialog2 = this.dialog.open(ConfirmColorsComponent, {
          width: '540px',
          data: { ...res, company_id: this.CompanyDetails.id }
        });

        dialog2.afterClosed().subscribe((res: any) => {
          if (res) {
            this.GetCompanyData()
          }
        })

      }
    })
  }

  OnSendPoints() {
    localStorage.setItem('balance', this.CompanyDetails?.balance?.points);
    // this._rewardedService.currentBalanceToSendMoney = this.CompanyDetails?.balance?.points;
    this.router.navigate(['/admin/send-points']);
  }

  OnChargeWallet() {
    const dialog = this.dialog.open(ChargeWalletComponent, {
      width: '540px'
    })
    dialog.afterClosed().subscribe((res: any) => { })
  }

  GetCompanyData() {
    let comapnyID = this._authService.currentUserValue?.company_id || ''
    this.dataLoading = true;
    this._companyProfileService.GetCompanyProfile(comapnyID).subscribe({
      next: (res: any) => {
        this.dataLoading = false;
        this.CompanyDetails = res.data;
        this.permissions = res.permissions
      },
      error: (error: any) => {
        this.dataLoading = false;
      }
    })
  }

  OnUpdateCompanyFiles() {
    const dialog = this.dialog.open(UpdateCompanyFilesComponent, {
      width: '540px',
      data: this.CompanyDetails
    })
    dialog.afterClosed().subscribe((res: any) => {
      if (res) {
        this.GetCompanyData()
      }
    })
  }

  OnUpdateCompanyInfo() {
    const dialog = this.dialog.open(UpdateCompanyInfoComponent, {
      width: '540px',
      data: this.CompanyDetails
    })
    dialog.afterClosed().subscribe((res: any) => {
      if (res) {
        this.GetCompanyData()
      }
    })
  }
}
