import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
})
export class BasicLayoutComponent implements OnInit {
  language = localStorage.getItem('language') || 'ar';
  moduleLoading: boolean = false;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    /*  router.events.subscribe((event: RouterEvent) => {
       this.navigationInterceptor(event);
     }); */
  }

  ngOnInit(): void {
  }

  // Shows and hides the moduleLoading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.moduleLoading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }

    // Set moduleLoading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
  }

  changeLanguage() {
    let params = JSON.parse(JSON.stringify(this.activatedRoute.snapshot.queryParams))
    let lang = this.language == 'ar' ? 'en' : 'ar'
    params['lang'] = lang
    localStorage.setItem('language', lang);
    // this.router.navigate(
    //   [],
    //   {
    //     relativeTo: this.activatedRoute,
    //     queryParams: params,
    //     queryParamsHandling: 'merge', // remove to replace all query params by provided
    //   });
    setTimeout(() => {
      window.location.replace(window.location.href.split("?")[0]);
    }, 100);
  }

}
