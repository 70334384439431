import { Component, Inject, OnInit } from '@angular/core';
import { DirtyComponent } from '../../../../helpers/CanDeactivate/dirty-component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CompanyProfileService } from '../services/company-profile.service';
import { AuthenticationService } from '../../../../services/authentication.service';

@Component({
  selector: 'app-update-company-colors',
  templateUrl: './update-company-colors.component.html',
  styles: ``
})
export class UpdateCompanyColorsComponent extends DirtyComponent implements OnInit {
  selectedColor: any = "#49D6D6";
  selectedTextColor: any = "#123636";
  loading: boolean = false;
  selectedType: 'text' | 'main' = 'main';
  override formGroup!: FormGroup<any>;
  constructor(override dialogRef: MatDialogRef<UpdateCompanyColorsComponent>,
    translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
  ) {
    super(translate)
  }
  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      color: [this.data.color?this.data.color:'#49D6D6', [Validators.required, Validators.minLength(6)]],
      font_color: [this.data.font_color?this.data.font_color:'#123636', [Validators.required, Validators.minLength(6)]]
    })
  }


  onSetColorValue(key: string, value: any) {
    this.formGroup.get(key)?.setValue(value);
    this.formGroup.markAsDirty();
  }

  onSubmitColors() {
    this.dialogRef.close({ ...this.formGroup.value, type: 'change' });
  }

  onResetColors() {
    this.dialogRef.close({ color: '#49D6D6', font_color: '#123636', type: 'reset' });
  }

}
