import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../services/api-service.service';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private _ApiService: ApiServiceService) { }

  viewUser(id: string) {
    return this._ApiService.get(`${environment.apiUrl}/user-management/users/${id}`)
      .pipe(catchError(this.handleError));
  }


  UpdatePassword(model: any) {
    return this._ApiService.post(`${environment.apiUrl}/user-management/update-my-password`, model)
      .pipe(catchError(this.handleError));
  }

  UpdateMyProfile(model: any) {
    return this._ApiService.post(`${environment.apiUrl}/user-management/update-my-profile`, model)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }

}
