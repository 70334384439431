import { Component, Inject } from '@angular/core';
import { DirtyComponent } from '../../../../../../helpers/CanDeactivate/dirty-component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { VendorProfileService } from '../../../services/vendor-profile.service';
import Swal from 'sweetalert2';
import { LookupsService } from '../../../../../../services/lookups.service';

export interface DialogData {
  details: any
}

@Component({
  selector: 'app-update-bank-account',
  templateUrl: './update-bank-account.component.html',
  styles: ``
})
export class UpdateBankAccountComponent extends DirtyComponent {
  loading = false;
  dataLoading = false;
  override formGroup!: FormGroup;
  isSubmitted = false;
  details!: any;

  banksList!: any;
  banksLoading = false;
  swiftCode!: string;

  constructor(
    override dialogRef: MatDialogRef<UpdateBankAccountComponent>,
    translate: TranslateService,
    private formBuilder: FormBuilder,
    private service: VendorProfileService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private lookupService: LookupsService) {
    super(translate);
  }

  ngOnInit(): void {
    this.details = this.data?.details;
    this.buildForm();
    this.getBanks();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      bank_id: [this.details ? this.details?.bank_id : null, [Validators.maxLength(100)]],
      iban: [this.details ? this.details?.iban : null, [Validators.maxLength(24), Validators.pattern('^SA.{22}$')]]
    })
  }
  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  // Banks
  getBanks() {
    this.banksLoading = true;
    this.lookupService.getBanks().subscribe((res: any) => {
      this.banksList = res.data;
      this.banksLoading = false;
    })
  }

  onSelectBank() {
    this.swiftCode = this.banksList.find((bank: any) => bank.id == this.formGroup.controls['bank_id'].value).swift_code
    // this.banksList.map((bank: any) => {
    //   if(bank.id == this.formGroup.controls['bank_id'].value) {
    //     this.swiftCode = bank.swift_code
    //   }
    // })
  }

  validateIBAN() {
    let IBAN = this.formGroup.controls['iban'].value;
    console.log(IBAN?.startsWith('SA') ? true : false)
    return IBAN && !IBAN?.startsWith('SA');
  }


  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;
      let model = { ...this.formGroup.value }
      // if (model.vendor_phone_number) {
      //   model.vendor_phone_number = '05' + model.vendor_phone_number;
      // }
      this.service.updateBankAccountData(this.details.id, model).subscribe((data) => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.updatedsuccessfully'),
          text: '',
          imageUrl: './assets/images/icons/success.svg',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
      });
    }
  }
}
